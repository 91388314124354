// @ts-ignore
import gql from "graphql-tag";

// @ts-ignore
import { Maybe, Fragment, Query, Mutation } from "base-types";

export type MutationStatus = "SUCCESS" | "FUTURE_VALUE";
export type MutationStatusKnownValue = "SUCCESS";
export const MutationStatusKnownValues: MutationStatusKnownValue[] = [ "SUCCESS" ];
export type CopilotApiUserRole = "ADMINISTRATOR" | "PRACTITIONER" | "FUTURE_VALUE";
export type CopilotApiUserRoleKnownValue = "ADMINISTRATOR" | "PRACTITIONER";
export const CopilotApiUserRoleKnownValues: CopilotApiUserRoleKnownValue[] = [ "ADMINISTRATOR","PRACTITIONER" ];
export type Permission = "USE_WEB_APP" | "VIEW_COPILOT_USERS" | "VIEW_API_KEYS" | "SEND_HELP_MESSAGES" | "FUTURE_VALUE";
export type PermissionKnownValue = "USE_WEB_APP" | "VIEW_COPILOT_USERS" | "VIEW_API_KEYS" | "SEND_HELP_MESSAGES";
export const PermissionKnownValues: PermissionKnownValue[] = [ "USE_WEB_APP","VIEW_COPILOT_USERS","VIEW_API_KEYS","SEND_HELP_MESSAGES" ];
export type SupportedLocale = "FRENCH" | "ENGLISH" | "PORTUGUESE" | "FUTURE_VALUE";
export type SupportedLocaleKnownValue = "FRENCH" | "ENGLISH" | "PORTUGUESE";
export const SupportedLocaleKnownValues: SupportedLocaleKnownValue[] = [ "FRENCH","ENGLISH","PORTUGUESE" ];
export type MeasurementSystem = "METRIC" | "IMPERIAL" | "FUTURE_VALUE";
export type MeasurementSystemKnownValue = "METRIC" | "IMPERIAL";
export const MeasurementSystemKnownValues: MeasurementSystemKnownValue[] = [ "METRIC","IMPERIAL" ];
export type CalendarStartOfWeek = "MONDAY" | "SUNDAY" | "FUTURE_VALUE";
export type CalendarStartOfWeekKnownValue = "MONDAY" | "SUNDAY";
export const CalendarStartOfWeekKnownValues: CalendarStartOfWeekKnownValue[] = [ "MONDAY","SUNDAY" ];
export type NablaProduct = "CARE_PLATFORM" | "COPILOT_CLINIC" | "COPILOT_API" | "FUTURE_VALUE";
export type NablaProductKnownValue = "CARE_PLATFORM" | "COPILOT_CLINIC" | "COPILOT_API";
export const NablaProductKnownValues: NablaProductKnownValue[] = [ "CARE_PLATFORM","COPILOT_CLINIC","COPILOT_API" ];



export type UpdateForStringInput = { value?: Maybe<string> }
export type UpdateSettingsCopilotApiInput = { firstName?: Maybe<UpdateForStringInput>; lastName?: Maybe<UpdateForStringInput> }
export type SendHelpMessageInput = { message: string }

export const possibleTypes: { [key in keyof PossibleTypes]: PossibleTypes[key][] } = {};
export type PossibleTypes = {

};

export type EntityName = 
  | "CopilotApiUser"
  | "Organization"
  | "SubOrganization"
  | "FileUpload"
  | "CopilotApiUsersWithActivity"
export type EntityFieldMap = { CopilotApiUser: "uuid" | "organization" | "subOrganization" | "canAccessOrganizationUserApi" | "email" | "roles" | "permissions" | "locale" | "timezone" | "firstName" | "lastName"; Organization: "uuid" | "stringId" | "displayName" | "product" | "whiteLabelEnabled"; SubOrganization: "uuid" | "organization" | "displayName" | "avatar" | "timezone" | "measurementSystem" | "calendarStartOfWeek" | "whiteLabelTitle" | "whiteLabelFaviconUrl"; FileUpload: "uuid" | "urlV2"; CopilotApiUsersWithActivity: "uuid" | "externalId" | "activationDaysCount" }
export type QueryVariablesMap = { copilotApiUsersActivityStats: "start" | "end" }

export type QueryName = 
  | "CopilotApiUsersActivityStats"
  | "CurrentCopilotApiUser"

const CopilotApiUserSummaryFragmentDocument = gql`
fragment CopilotApiUserSummary on CopilotApiUser {
  uuid
  email
  firstName
  lastName
  locale
  timezone
}

`
export const CopilotApiUserSummaryFragmentProps: Fragment<CopilotApiUserSummaryFragment> = {
  document: CopilotApiUserSummaryFragmentDocument,
  fragmentName: "CopilotApiUserSummary",
  entityName: "CopilotApiUser",
  __do_not_use_Data: null
}
export type CopilotApiUserSummaryFragment = {
  __typename: "CopilotApiUser";
  uuid: UUID;
  email: Maybe<string>;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  locale: SupportedLocale;
  timezone: TimeZone
}

export type UpdateSettingsData = { __typename: "UpdateSettingsCopilotApiOutput"; user: CopilotApiUserSummaryFragment }
export type UpdateSettingsVariables = { input: UpdateSettingsCopilotApiInput }
export const UpdateSettings: Mutation<UpdateSettingsData, UpdateSettingsVariables, "COPILOT_API_USER"> = {
  schemaType: "COPILOT_API_USER",
  document: gql`
mutation UpdateSettings($input: UpdateSettingsCopilotApiInput!) {
  updateSettings(input: $input) {
    user {
      ...CopilotApiUserSummary
    }
  }
}
${CopilotApiUserSummaryFragmentDocument}
`,
  endpointName: "updateSettings",
  updateInputsPaths: ["input.firstName","input.lastName"],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CopilotApiUsersActivityStatsData = { __typename: "CopilotApiUserActivityStatsOutput"; copilotApiUsersWithActivity: Array<{ __typename: "CopilotApiUsersWithActivity"; uuid: UUID; externalId: Maybe<string>; activationDaysCount: Int }> }
export type CopilotApiUsersActivityStatsVariables = { start: ISOString; end: ISOString }
export const CopilotApiUsersActivityStats: Query<CopilotApiUsersActivityStatsData, CopilotApiUsersActivityStatsVariables, true, "COPILOT_API_USER"> = {
  schemaType: "COPILOT_API_USER",
  document: gql`
query CopilotApiUsersActivityStats($start: DateTime!, $end: DateTime!) {
  copilotApiUsersActivityStats(start: $start, end: $end) {
    copilotApiUsersWithActivity {
      uuid
      externalId
      activationDaysCount
    }
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type SendCopilotApiUserHelpMessageData = { __typename: "SendHelpMessageOutput"; status: MutationStatus }
export type SendCopilotApiUserHelpMessageVariables = { input: SendHelpMessageInput }
export const SendCopilotApiUserHelpMessage: Mutation<SendCopilotApiUserHelpMessageData, SendCopilotApiUserHelpMessageVariables, "COPILOT_API_USER"> = {
  schemaType: "COPILOT_API_USER",
  document: gql`
mutation SendCopilotApiUserHelpMessage($input: SendHelpMessageInput!) {
  sendHelpMessage(input: $input) {
    status
  }
}

`,
  endpointName: "sendHelpMessage",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CurrentCopilotApiUserData = { __typename: "MeOutput"; user: CopilotApiUserSummaryFragment&{ roles: Array<CopilotApiUserRole>; permissions: Array<Permission>; canAccessOrganizationUserApi: boolean; subOrganization: {
  __typename: "SubOrganization";
  uuid: UUID;
  displayName: Maybe<string>;
  timezone: TimeZone;
  measurementSystem: MeasurementSystem;
  calendarStartOfWeek: CalendarStartOfWeek;
  whiteLabelTitle: Maybe<string>;
  whiteLabelFaviconUrl: Maybe<string>;
  avatar: Maybe<{ __typename: "FileUpload"; uuid: string; urlV2: { __typename: "EphemeralUrl"; url: string; expiresAt: ISOString } }>;
  organization: { __typename: "Organization"; uuid: UUID; stringId: string; displayName: string; whiteLabelEnabled: boolean }
} } }
export type CurrentCopilotApiUserVariables = { [key: string]: never }
export const CurrentCopilotApiUser: Query<CurrentCopilotApiUserData, CurrentCopilotApiUserVariables, false, "COPILOT_API_USER"> = {
  schemaType: "COPILOT_API_USER",
  document: gql`
query CurrentCopilotApiUser {
  me {
    user {
      ...CopilotApiUserSummary
      roles
      permissions
      canAccessOrganizationUserApi
      subOrganization {
        uuid
        displayName
        timezone
        measurementSystem
        calendarStartOfWeek
        whiteLabelTitle
        whiteLabelFaviconUrl
        avatar {
          uuid
          urlV2 {
            url
            expiresAt
          }
        }
        organization {
          uuid
          stringId
          displayName
          whiteLabelEnabled
        }
      }
    }
  }
}
${CopilotApiUserSummaryFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}